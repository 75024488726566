[
  {
    "names": [
      "goldstack-ci-test-server-side-rendering-1706307820800-posts.map"
    ],
    "generatedName": "posts.157e75912888bb6f53b5e37c4fd2a7c5.map.json"
  },
  {
    "names": [
      "goldstack-ci-test-server-side-rendering-1706307820800-posts-bundle.js"
    ],
    "generatedName": "posts-bundle.713cdc2391efb06b78bd764565dd82cf.js"
  }
]