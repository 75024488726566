{
  "$schema": "./schemas/package.schema.json",
  "name": "server-side-rendering-1",
  "template": "server-side-rendering",
  "templateVersion": "0.1.0",
  "configuration": {},
  "deployments": [
    {
      "name": "prod",
      "awsUser": "goldstack-dev",
      "awsRegion": "us-west-2",
      "configuration": {
        "lambdaNamePrefix": "goldstack-ci-test-server-side-rendering-1706307820800",
        "domain": "server-side-rendering-1706307820800.tests.dev.goldstack.party",
        "hostedZoneDomain": "dev.goldstack.party",
        "cors": "",
        "lambdas": {
          "ANY /posts": {
            "function_name": "goldstack-ci-test-server-side-rendering-1706307820800-posts",
            "route": "ANY /posts"
          },
          "ANY /": {
            "function_name": "goldstack-ci-test-server-side-rendering-1706307820800--__index",
            "route": "ANY /"
          }
        }
      },
      "tfVersion": "1.6",
      "tfStateKey": "server-side-rendering-1-prod-d71dadf8247665f85108.tfstate"
    }
  ]
}